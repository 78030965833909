<template>
  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 card chat-box-area" v-if="!isLoadingRooms">
    <div class="phone-chat-header">
      <div class="back-chat-icon">
        <svg @click="back" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="back-chat-arrow"><path fill="#080025" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
        </svg>
      </div>
      <div class="chat-right-box-inner">
        <div class="first">
          <UserCard v-if="interlocutor" :user="interlocutor"></UserCard>
        </div>
      </div>
    </div>
    <div class="card-body chat-conversation-section scrollbar" ref="scrollSection" @click="loadNewMessages">
      <ScrollArea :settings="ScrollSettings" style="padding: 26px 10px;">
        <ChatMessage v-for="message in formattedMessages" :message="message" :user_id="auth.id"/>
        <div v-if="coordonnee" class="make-payment-box">
          <i class="fas fa-exclamation"></i>
          <p>{{ $t("chatroom.cg") }} </p>
        </div>
      </ScrollArea>
    </div>
    <div class="type-box card-footer">
      <div v-if="!interlocutor.lightyShareCertifed" class="certification-badge">
        <span class="certification-icon"></span>
        <span class="certification-description">
          {{ $t('certification-badge.description-vue', {firstname: interlocutor.prenom }) }}
              </span>
        <a class="read-more" target="_blank" href="https://support.lightyshare.com/article/195-un-membre-non-certifie-ma-envoye-une-demande-de-location">
          {{ $t('certification-badge.more-content') }}
          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-right">
              <path id="Icon" d="M2.7085 7.00065H10.2918M10.2918 7.00065L6.50016 3.20898M10.2918 7.00065L6.50016 10.7923" stroke="#080025" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </a>
      </div>
      <div class="box scroller">
        <form id="">
          <!-- <input type="text" name="" class="input" placeholder="Votre message"> -->
          <textarea placeholder="Votre message" class="" v-model="newMessages[roomId]" @keydown="autosize" ></textarea>
          <button class="send-btn" @click.prevent="sendMessage"><i class="fas fa-paper-plane"></i></button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 chat-right-box" v-if="!isLoadingRooms">
    <div class="chat-right-box-inner">
      <div class="first">
        <UserCard v-if="interlocutor" :user="interlocutor"></UserCard>
      </div>
      <div v-if="!interlocutor.lightyShareCertifed" class="certification-badge">
        <span class="certification-title">
          <span class="certification-icon"></span>
          {{ $t('certification-badge.chat.title') }}
        </span>
        <span class="certification-description">
          {{ $t('certification-badge.chat.description', {firstname: interlocutor.prenom }) }}
              </span>
        <a class="read-more" target="_blank" href="https://support.lightyshare.com/article/195-un-membre-non-certifie-ma-envoye-une-demande-de-location">
          {{ $t('certification-badge.more-content') }}
          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-right">
              <path id="Icon" d="M2.7085 7.00065H10.2918M10.2918 7.00065L6.50016 3.20898M10.2918 7.00065L6.50016 10.7923" stroke="#080025" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </a>
      </div>
      <div class="dernieres" v-if="roomLocations.length">
        <h4>{{ $t("chatroom.last") }}</h4>
        <LocationTicket v-for="location in formattedRoomLocations" :demandeLocation="location" :auth="auth"></LocationTicket>
      </div>
      <div class="dernieres" v-if="interlocutor.boutiqueActive">
        <div class="btn-area">
          <a :href="boutiqueLink" class="btn bn-primary">{{ $t("chatroom.shop") }}</a>
        </div>
      </div>
      <div class="dernieres" v-else>
        <div class="btn-area">
          <a :href="boutiqueLink" class="btn bn-primary">{{ $t("chatroom.profile") }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12 card chat-box-area loadingBg" v-if="isLoadingRooms">
    <div class="loader">
      <div class="multi-ripple">
        <div></div>
        <div></div>
      </div>
      <p class="loading-text">{{ $t("chatroom.loading1") }} <br> {{ $t("chatroom.loading2") }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ChatMessage from "./ChatMessage.vue";
import ScrollArea from "./ScrollArea.vue";
import UserCard from "./UserCard.vue";
import {sendMessage, loadNewMessage, readRoom, checkNewRoom} from "../services/chat";
import LocationTicket from "./LocationTicket.vue";

export default {
  components: {
    LocationTicket,
    UserCard,
    ScrollArea,
    ChatMessage,
  },
  name: 'ChatRoom',
  props: {
    flagMode: {
      default: false
    },
    newRoom: {
      type: Boolean,
      default: false
    }
    // room: {
    //   type: Object
    // }
  },
  data () {
    return {
      moderatorMode: false,
      interlocutorLoaded: false,
      isSendingMessage: false,
      scanMessagesIds: [],
      coordonnee: false,
      mounted: false,
      isLoadingRooms: false,
      waitingToRead: [],
      newMessages: {},
      messages: [],
      newInterlocutor: false,
      auth: false,
      ScrollSettings: {
        suppressScrollX: true
      }
    }
  },
  computed: {
    boutiqueLink () {
      return '/public/' + this.interlocutor.id + '/show'
    },
    newRoomUserId () {
      return this.$route.name === 'room_create' ? this.$route.params.id : false
    },
    interlocutorName () {
      if (!this.interlocutor) {
        return ''
      }
      return this.interlocutor.prenom + ' ' + this.interlocutor.nom.charAt(0)
    },
    initial () {
      if (!this.interlocutor) {
        return ''
      }
      return this.interlocutor.prenom.charAt(0) + "" + this.interlocutor.nom.charAt(0)
    },
    roomId () {
      return this.$route.name === 'room_select' ? this.$route.params.id : false
    },
    interlocutor () {
      if (this.interlocutorLoaded) {
        return this.interlocutorLoaded
      } else if (this.newRoomUserId) {
        return this.newInterlocutor
      } else if (this.formattedMessages.length) {
        return this.formattedMessages[0].receiver.id === this.auth.id ? this.formattedMessages[0].user : this.formattedMessages[0].receiver
      } else {
        return false
      }
    },
    formattedRoomLocations () {
      let inc = 0
      return this.roomLocations.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }).filter((el, i) => {
        return i < 3
      })
    },
    roomLocations () {
      const messages = Array.isArray(this.messages) ? this.messages : []
      return messages.reduce((locations, message) => {
        if (message.demandeLocation) {
          const isExist = locations.find(l => message.demandeLocation && l.id === message.demandeLocation.id)
          if (!isExist) {
            locations.push(message.demandeLocation)
          }
        }
        return locations
      }, [])
    },
    formattedMessages () {
      let createdAt = []
      let acceptedAt = []
      let paidAt = []
      let canceledAt = []

      return this.messages.map((message) => {
        message['date'] = false
        message['DLCardsAfter'] = []
        message['DLCardsBefore'] = []

        if (message['demandeLocation'] !== null) {
          let DL = message['demandeLocation']
          console.log('DL raw dates:', {
            paidAt: DL['paidAt'],
            canceledAt: DL['canceledAt']
          })

          let statusChanges = []
          
          if (!createdAt.includes(DL['id']) && DL['createdAt']) {
            createdAt.push(DL['id'])
            statusChanges.push({
              timestamp: this.$moment(DL['createdAt']).unix(),
              state: 'created',
              data: DL
            })
          }

          if (!acceptedAt.includes(DL['id']) && DL['acceptedAt']) {
            acceptedAt.push(DL['id'])
            statusChanges.push({
              timestamp: this.$moment(DL['acceptedAt']).unix(),
              state: 'accepted',
              data: DL
            })
          }

          if (!paidAt.includes(DL['id']) && DL['paidAt']) {
            const paidTimestamp = this.$moment(DL['paidAt']).unix()
            console.log('Paid timestamp:', paidTimestamp, new Date(DL['paidAt']))
            paidAt.push(DL['id'])
            statusChanges.push({
              timestamp: paidTimestamp,
              state: 'paid',
              data: DL
            })
          }

          if (!canceledAt.includes(DL['id']) && DL['canceledAt']) {
            const canceledTimestamp = this.$moment(DL['canceledAt']).unix()
            console.log('Canceled timestamp:', canceledTimestamp, new Date(DL['canceledAt']))
            canceledAt.push(DL['id'])
            statusChanges.push({
              timestamp: canceledTimestamp,
              state: 'canceled',
              data: DL
            })
          }

          // Sort status changes by timestamp only
          statusChanges.sort((a, b) => a.timestamp - b.timestamp) // Changed back to ascending order
          console.log('Sorted status changes:', statusChanges.map(s => ({
            state: s.state,
            timestamp: s.timestamp,
            date: new Date(s.timestamp * 1000)
          })))

          message['DLCardsAfter'] = statusChanges.map(change => ({
            ...change.data,
            state: change.state
          }))
          message['DLCardsBefore'] = []
        }

        // Handle date display
        if (this.oldDay === false) {
          message['date'] = message.createdAt
          this.oldDay = this.$moment(message.createdAt).unix()
        }
        if (this.$moment(message.createdAt).unix() - this.oldDay > 60*60*24) {
          message['date'] = message.createdAt
          this.oldDay = this.$moment(message.createdAt).unix()
        }

        return message
      })
    },
    formattedMessagesOld () {
      let newDemandeAdded = []
      let canceledDemandeAdded = []
      const messages = Array.isArray(this.messages) ? this.messages : []
      let currentDL = false
      return messages.reduce((formattedMessages, message, key, messages) => {
        if (message.demandeLocation && !newDemandeAdded.includes(message.demandeLocation.id)) {
          currentDL = {...message.demandeLocation, ...message.locationDates}
          newDemandeAdded.push(message.demandeLocation.id)
          formattedMessages.push({
            type: 'demandeLocation',
            ...message
          })
        }
        formattedMessages.push({
          ...message,
          type: 'message'
        })
        let needToCancel = currentDL.canceledAt && !canceledDemandeAdded.includes(message.demandeLocation.id) && new Date(currentDL.canceledAt) > new Date(message.createdAt)
        if (needToCancel) {
          canceledDemandeAdded.push(message.demandeLocation.id)
          formattedMessages.push({
            type: 'demandeLocation',
            status: 'cancel',
            ...message
          })
        }
        return formattedMessages
      }, [])
    }
  },
  watch: {
    newRoomUserId: {
      immediate: true,
      async handler (val, oldVal) {
        if (val !== oldVal) {
          if (val !== false) {
            await this.loadNewRoom(val)
            if (this.mounted) {
              await this.scrollToEnd(this.$refs.scrollSection)
            }
          }
        }
      }
    },
    roomId: {
      immediate: true,
      async handler (val, oldVal) {
        if (val !== oldVal) {
          this.coordonnee = false
          if (val !== false) {
            await this.loadMessages()
            if (this.mounted) {
              await this.scrollToEnd(this.$refs.scrollSection)
            }
          }
        }
      }
    }
  },
  unmounted() {
    this.emitter.off("received-message")
  },
  mounted() {
    this.mounted = true
    if (!this.isLoadingRooms) {
      this.scrollToEnd(this.$refs.scrollSection)
    }
    this.emitter.on("received-message", async (message) => {
      const hasReceiverMessage = message.messages.filter((m) => {
        return m.receiver.id === this.auth.id
      })
      if (message.messages.length && hasReceiverMessage.length) {
        // console.log('new message')
        // console.log(message)
        await this.pushMessages(message.messages, 'waitingToRead')
        await this.pushMessages(message.messages, 'messages')
        await this.scrollToEnd()
        if (this.roomId) {
          // console.log('read room')
          await readRoom(this.roomId)
        }
      }
      // console.log('receive message')
      // console.log(message)
    });
    // window.onfocus = function () {
    //   this.isTabActive = true;
    // };
    //
    // window.onblur = function () {
    //   this.isTabActive = false;
    // };
    // console.log(this.$refs.scrollSection)
  },
  methods: {
    async loadNewRoom (user_id) {
      this.isLoadingRooms = true
      const newRoomResponse = await checkNewRoom(user_id)
      // console.log('newRoomResponse')
      // console.log(newRoomResponse)
      if (newRoomResponse && newRoomResponse.data && newRoomResponse.data.interlocutor) {
        this.newInterlocutor = newRoomResponse.data.interlocutor
      }
      if (newRoomResponse && newRoomResponse.data && newRoomResponse.data.messages) {
        await this.pushMessages(newRoomResponse.data.messages, 'messages')
      }
      if (newRoomResponse && newRoomResponse.data && newRoomResponse.data.auth) {
        this.auth = newRoomResponse.data.auth
      }
      this.isLoadingRooms = false
    },
    async scanMessages () {
      if (!this.coordonnee && Array.isArray(this.messages)) {
        this.messages.filter((m) => {
          return !this.scanMessagesIds.includes(m.id)
        }).forEach((m) => {
          // if (m.content) {
            const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          const phoneRegex = /(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/
          // }
          const hasMailRegex = mailRegex.test(m.content)
          const hasPhone = phoneRegex.test(m.content)
          this.scanMessagesIds.push(m.id)
          if (hasMailRegex || hasPhone) {
            this.coordonnee = true
          }
        })
      }
    },
    async autosize (evt) {
      const el = evt.target
      setTimeout(function(){
        el.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
        // el.scrollTop = el.scrollHeight - el.clientHeight;
        /* e.style.overflow = (e.scrollHeight > h ? "auto" : "hidden");*/
      },0);
      // console.log(evt.target)
    },
    async scrollToEnd(el = false) {
      // console.log('scroll to end' )
      if (el === false) {
        el = this.$refs.scrollSection
      }
      // console.log(el)
      el.scrollTop = el.scrollHeight - el.clientHeight;
    },
    async back () {
      await this.$router.push('/user/messagerie')
    },
    async loadNewMessages () {
      // console.log('load New message')
      const rep = await loadNewMessage()

    },
    async pushMessages (messages, key = 'messages') {
      messages.forEach((v) => {
        const allreadyExist = this[key].findIndex((r) => {
          return r.id === v.id
        })
        // console.log('allreadyExist')
        // console.log(allreadyExist)
        if (allreadyExist === -1) {
          this[key].push(v)
        } else {
          this[key][allreadyExist] = v
        }
      })
    },
    async sendMessage () {
      if (!this.isSendingMessage && typeof this.newMessages[this.roomId] === 'string' && this.newMessages[this.roomId].trim().length) {
        this.isSendingMessage = true
        const rep = await sendMessage(this.newMessages[this.roomId], this.roomId, this.auth.id, this.interlocutor.id)
        // console.log(rep)
        if (rep && rep.data && rep.data.success) {
          this.newMessages[this.roomId] = ''
          // console.log('update room ' + rep.data.room)
          if (rep.data.room) {
            // console.log('update room')
            this.emitter.emit('UpdateRoom', rep.data.room)
          }
          if (rep.data.message) {
            try {
              await this.pushMessages(rep.data.message)
              await this.scrollToEnd()
            } finally {
              this.isSendingMessage = false
            }
          }
        }
        this.isSendingMessage = false
      }

    },
    async loadMessages () {
      if (this.roomId) {
        this.isLoadingRooms = true
        let url = '/user/room/messages/' + this.roomId
        if (this.$lightyshareConfig && this.$lightyshareConfig.api_base_url) {
          url = this.$lightyshareConfig.api_base_url + url
        }
        const response = await axios.get(url, {
        })
        // console.log('load Messages')
        // console.log(response)
        if (response.status === 200) {
          this.messages = response.data.messages
          this.auth = response.data.auth
          this.moderatorMode = response.data.mod ? response.data.mod : false;
          this.interlocutorLoaded = response.data.interlocutor
          this.scanMessages().then(() => {
            // console.log('message scanned')
          })
        }
        this.isLoadingRooms = false;
      }
    }
  }
}
</script>

<style lang="scss">
//$charade: #282a37;
$bluebell: rgb(231, 29, 65, 0.5);
.loading-text {
  text-align: center;
  color: #A3A3A3;
}
.loadingBg {
  display: flex !important;
  //flex-direction: column;
  align-items: center;
  justify-content: center;
}
.multi-ripple {
  width: 2.6rem;
  height: 2.6rem;
  //margin-top: 50%;
  margin: 0.5rem 2rem;

  div {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 0.3rem solid $bluebell;
    animation: 1.5s ripple infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
}
@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
