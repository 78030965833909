<script>
import FormHandler from "~/mixins/formHandler";
import Rental from "~/modules/ask/services/Rental";
import { RemoveFilled, CirclePlusFilled, InfoFilled, WarningFilled } from '@element-plus/icons-vue'

export default {
  name: "CancelEdit",
  components: {
    RemoveFilled,
    CirclePlusFilled,
    InfoFilled,
    WarningFilled
  },
  emits: ['change'],
  props: {
    userType: {
      type: String,
      default: ''
    },
    ask: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data () {
    return {
      now: this.$moment(),
      nowInterval: null,
      reason: '0',
      edit: {
        reason: '0',
        otherReason: '',
        message: ''
      },
      nbCancellations: null
    }
  },
  async created() {
    if (this.userType === 'tenant' && this.ask && this.ask.locataire && this.ask.locataire.id) {
      try {
        const response = await fetch(`/api/rental/cancellations/${this.ask.locataire.id}`);
        const data = await response.json();
        this.nbCancellations = data;
      } catch (error) {
        console.error('Error fetching cancellations:', error);
      }
    }
  },
  computed: {
    getNowFormatted () {
      return this.now.format('DD/MM/YYYY HH:mm')
    },
    timeAfter3hourForPaidAt () {
      if (this.ask && this.ask.paidAtUnix) {
        const paidAt = this.$moment(this.ask.paidAtUnix, 'X')
        return paidAt.add(3, 'hours').format('HH:mm').replace(':', 'h')
      } else {
        return false
      }

    },
    isInTerms () {
      if (this.userType === 'owner') {
        return false
      }
      return true
    },
    nbDaysBeforeDeparture () {
      if (this.ask && this.ask.departUnix) {
        return this.$moment(this.ask.departUnix, 'X').diff(this.now, 'days', true).toFixed(2)
      } else {
        return false
      }
    },
    termDetails () {
      if (this.isInTerms) {
        if (this.ask && this.ask.typeAnnulation) {
          // Si on est dans les 3h après le paiement, pas de pénalités
          if (this.paymentLessThan3Hours) {
            return {
              penaltyPercent: 0,
              penaltyAmount: 0,
              penaltyTenantFees: false
            }
          }

          const nbDaysBeforeDeparture = this.$moment(this.ask.departUnix, 'X').diff(this.now, 'days', true).toFixed(2)
          const tenantTotalCost = this.ask.prix_total_ttc - this.ask.assurance_ht - this.ask.frais_service_ttc
          
          // Les frais de service sont facturés si c'est la deuxième annulation dans l'année
          const penaltyTenantFees = this.nbCancellations > 0

          let details = {
            penaltyPercent: 0,
            penaltyAmount: 0,
            penaltyTenantFees
          }

          if (this.ask.typeAnnulation.id === 1) {
            if (nbDaysBeforeDeparture >= 2) {
              details.penaltyPercent = 0
              details.penaltyAmount = 0
            } else if (nbDaysBeforeDeparture > 0 && nbDaysBeforeDeparture < 2) {
              details.penaltyPercent = 25
              details.penaltyAmount = tenantTotalCost * 0.25
            } else if (nbDaysBeforeDeparture <= 0) {
              details.penaltyPercent = 50
              details.penaltyAmount = tenantTotalCost * 0.50
            }
          } else if (this.ask.typeAnnulation.id === 2) {
            if (nbDaysBeforeDeparture >= 3) {
              details.penaltyPercent = 0
              details.penaltyAmount = 0
            } else if (nbDaysBeforeDeparture > 1 && nbDaysBeforeDeparture < 3) {
              details.penaltyPercent = 25
              details.penaltyAmount = tenantTotalCost * 0.25
            } else if (nbDaysBeforeDeparture > 0 && nbDaysBeforeDeparture <= 1) {
              details.penaltyPercent = 50
              details.penaltyAmount = tenantTotalCost * 0.50
            } else {
              details.penaltyPercent = 100
              details.penaltyAmount = tenantTotalCost
            }
          } else if (this.ask.typeAnnulation.id === 3) {
            if (nbDaysBeforeDeparture >= 10) {
              details.penaltyPercent = 0
              details.penaltyAmount = 0
            } else if (nbDaysBeforeDeparture >= 5 && nbDaysBeforeDeparture < 10) {
              details.penaltyPercent = 25
              details.penaltyAmount = tenantTotalCost * 0.25
            } else {
              details.penaltyPercent = 100
              details.penaltyAmount = tenantTotalCost
            }
          }

          return details
        }
      }
      
      return {
        penaltyPercent: 0,
        penaltyAmount: 0,
        penaltyTenantFees: this.nbCancellations > 0
      }
    },
    paymentLessThan3Hours () {
      if (this.ask && this.ask.paidAtUnix) {
        const paidAt = this.$moment(this.ask.paidAtUnix, 'X')
        const now = this.$moment()
        const diffHours = now.diff(paidAt, 'hours')
        const diffMinutes = now.diff(paidAt, 'minutes') % 60
        const totalHours = diffHours + (diffMinutes / 60)
        return totalHours < 3
      }
      return false
    },
    username () {
      if (!this.ask) return ''
      
      let name = ''
      
      // Si c'est le locataire qui annule, on affiche le prénom du propriétaire
      if (this.userType === 'tenant' && this.ask.proprietaire && this.ask.proprietaire.prenom) {
        name = this.ask.proprietaire.prenom
      }
      
      // Si c'est le propriétaire qui annule, on affiche le prénom du locataire
      if (this.userType === 'owner' && this.ask.locataire && this.ask.locataire.prenom) {
        name = this.ask.locataire.prenom
      }
      
      // Formater le nom avec majuscule au début de chaque mot
      return name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    },
    isMessageEmpty () {
      return !this.edit.message || this.edit.message.trim().length === 0
    },
    stepThree () {
      return this.ask.AcceptedAt !== null && this.ask.PaidAt !== null && this.termined == false && this.ask.CanceledAt === null ? true : false
    },
    shouldShowNegativeReview() {
        if (!this.ask || !this.termDetails) return false
        const nbDays = this.$moment(this.ask.departUnix, 'X').diff(this.now, 'days', true)
        return nbDays < 7 && !this.paymentLessThan3Hours && this.termDetails.penaltyAmount > 0
    }
  },
  methods: {
    change () {
      console.log('change')
      this.$emit('change', { ...this.edit, isValid: !this.isMessageEmpty })
    },
    formatPrice (price) {
      // Si la valeur est undefined ou null, on retourne 0
      if (price === undefined || price === null) {
        price = 0
      }
      // Convertir en nombre si c'est une string
      if (typeof price === 'string') {
        price = parseFloat(price)
      }
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    },
    calculateRefund() {
      if (!this.ask || !this.termDetails) {
        return 0
      }
      
      // Convertir toutes les valeurs en nombres, avec 0 comme fallback
      const totalTTC = parseFloat(this.ask.prix_total_ttc) || 0
      const fraisService = parseFloat(this.ask.frais_service_ttc) || 0
      const assurance = parseFloat(this.ask.assurance_ht) || 0
      const penaltyAmount = parseFloat(this.termDetails.penaltyAmount) || 0
      const refundServiceFees = this.termDetails.penaltyTenantFees ? 0 : fraisService

      return totalTTC - fraisService - assurance - penaltyAmount + assurance + refundServiceFees
    },
    updateMomentNow () {
      this.now = this.$moment()
    }
  },
  mounted () {
    this.now = this.$moment()
    this.nowInterval = setInterval(this.updateMomentNow, 1000)
  },
  unmounted () {
    clearInterval(this.nowInterval)
  }
}
</script>

<template>
  <el-form label-position="top" size="large">
    <div v-if="userType === 'owner'">
      <el-radio-group v-model="edit.reason" class="vertical-radio-list" @change="change">
        <el-radio label="0" size="large" class="responsive-radio">Votre matériel n'est pas disponible sur cette période</el-radio>
        <el-radio label="1" size="large">Vous n'êtes pas disponible sur cette période</el-radio>
        <el-radio label="2" size="large">Autre raison</el-radio>
      </el-radio-group>
      <el-form-item label="Raison" v-if="edit.reason == 2">
        <el-input v-model="edit.otherReason" placeholder="Raison" @change="change"></el-input>
      </el-form-item>
      <el-form-item :label="'Laissez un message :'">
        <el-input v-model="edit.message" type="textarea" placeholder="Message" @change="change"></el-input>
      </el-form-item>
    </div>
    <div v-if="userType === 'tenant'" class="cancel-content">
      <div v-if="!ask.PaidAt">
        <p class="alert alert-info">
          Votre demande de location n'a pas encore été payée. L'annulation n'entraînera aucun frais.
        </p>
      </div>
      <div v-else-if="ask && isInTerms && !paymentLessThan3Hours">
        <div class="mb-3">
          <p class="mb-0">
            {{$t('modals.cancel.tenant.policy', {type: ask.typeAnnulation.type})}}
            <a href="https://support.lightyshare.com/article/93-comment-annuler-une-reservation-validee" target="__blank" class="text-muted text-decoration-underline ms-1">
              {{$t('modals.cancel.tenant.moreInfo')}}
            </a>
          </p>
        </div>

        <p>
          En annulant cette location de <strong>{{formatPrice(ask.prix_total_ttc)}}</strong>, 
          <strong>{{nbDaysBeforeDeparture}}</strong> jours avant le départ :
        </p>

        <div class="fees-section p-3 bg-light rounded">
          <ul class="list-unstyled mb-0">
            <li v-if="termDetails && termDetails.penaltyPercent" class="mb-3 d-flex align-items-center">
              <el-icon class="text-danger" style="margin-right: 1rem;"><CircleClose /></el-icon>
              <div>
                <strong>Pénalité {{termDetails.penaltyPercent}}% : {{formatPrice(termDetails.penaltyAmount)}}</strong>
                <div class="text-muted small">(Annulable dans les 24h par le loueur)</div>
              </div>
            </li>
            <li class="mb-3 d-flex align-items-center">
              <el-icon class="text-success" style="margin-right: 1rem;"><CircleCheck /></el-icon>
              <div>
                <strong>{{$t('modals.cancel.tenant.fees.insurance', {amount: formatPrice(ask.assurance_ht)})}}</strong>
              </div>
            </li>
            <li v-if="termDetails && termDetails.penaltyTenantFees" class="mb-3 d-flex align-items-center">
              <el-icon class="text-danger" style="margin-right: 1rem;"><CircleClose /></el-icon>
              <div class="d-flex align-items-center">
                <strong>{{$t('modals.cancel.tenant.fees.service.charged', {amount: formatPrice(ask.frais_service_ttc)})}}</strong>
                <el-tooltip content="Les frais de service sont conservés à partir de la deuxième annulation dans les 12 derniers mois" placement="top" popper-class="cancel-tooltip" :z-index="10000">
                  <el-icon class="ms-2 text-muted"><InfoFilled /></el-icon>
                </el-tooltip>
              </div>
            </li>
            <li v-else class="mb-3 d-flex align-items-center">
              <el-icon class="text-success" style="margin-right: 1rem;"><CircleCheck /></el-icon>
              <div class="d-flex align-items-center">
                <strong>{{$t('modals.cancel.tenant.fees.service.refunded', {amount: formatPrice(ask.frais_service_ttc)})}}</strong>
                <el-tooltip content="Les frais de service sont remboursés pour votre première annulation dans les 12 derniers mois" placement="top" popper-class="cancel-tooltip" :z-index="10000">
                  <el-icon class="ms-2 text-muted"><InfoFilled /></el-icon>
                </el-tooltip>
              </div>
            </li>
            <li v-if="shouldShowNegativeReview" class="d-flex align-items-center">
              <el-icon class="text-danger" style="margin-right: 1rem;"><Warning /></el-icon>
              <div>
                <strong>{{$t('modals.cancel.tenant.badNotation')}}</strong>
              </div>
            </li>
          </ul>
        </div>

        <div class="bg-light rounded p-3 mt-4">
          <div class="d-flex flex-column">
            <span class="mb-1">Total remboursé après annulation :</span>
            <span class="h4 mb-0">{{formatPrice(calculateRefund())}}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="alert alert-success">
          En annulant maintenant vous obtenez un remboursement intégral de {{formatPrice(ask.prix_total_ttc)}}
        </p>
        <p class="mt-3" v-if="paymentLessThan3Hours">
          <el-icon style="color: #FC2249;"><WarningFilled /></el-icon>
          Des pénalités peuvent être appliquées en cas d'annulation après {{timeAfter3hourForPaidAt}} en fonction des conditions d'annulation du loueur
        </p>
      </div>
      <div class="mt-4">
        <label class="form-label">{{$t('modals.cancel.tenant.leaveMessage', {user: username})}}</label>
        <textarea 
          v-model="edit.message" 
          class="form-control textarea" 
          :placeholder="$t('modals.cancel.tenant.placeholder', {user: username})"
          @input="change"
          rows="5"
        ></textarea>
      </div>
    </div>
  </el-form>
</template>
