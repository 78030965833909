<template>
  <div class="date" style="text-align: center;" v-if="formattedDate!== false">
    <p class="message-date-box">{{formattedDate}}</p>
  </div>
  <LocationCard v-for="card in DLCardsBefore" :DLCard="card"/>
  <div :class="messageClass">
    <div class="img" v-if="isOwner">
      <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + message.user.id + '/profil/' + image" alt="">
      <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
        <div class="initials">{{initial}}</div>
      </div>
    </div>
    <div class="text">
      <div :class="'msg ' + messageClass">
      <p style="width: 100%" v-html="message.content"></p>
      <div class="message-hour">{{formattedHour}}</div>
      </div>
    </div>
    <div class="img" v-if="!isOwner">
      <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + message.user.id + '/profil/' + image" alt="">
      <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
        <div class="initials">{{initial}}</div>
      </div>
    </div>
  </div>
  <LocationCard v-for="card in DLCardsAfter" :DLCard="card" />
</template>

<script>
import LocationCard from "./LocationCard.vue"

export default {
  name: 'ChatMessage',
  components: {
    LocationCard
  },
  props: {
    user_id: {
      type: [String, Number]
    },
    message: {
      type: Object
    }
  },
  data () {
    return {
      messages: [],
      DLCards: []
    }
  },
  computed: {
    formattedDate () {
      if(this.message.date !== false)
      {
        return this.$moment(this.message.date).format('dddd D MMMM')
      } else {
        return false
      }
    },
    formattedHour () {
      return this.$moment(this.message.createdAt).format('HH:mm')
    },
    initial () {
      return this.owner.prenom.charAt(0) + "" + this.owner.nom.charAt(0)
    },
    image () {
      return this.message.user && this.message.user.ImageProfil && this.message.user.ImageProfil.imageName
    },
    isOwner () {
      return this.message && this.message.user && this.user_id !== this.message.user.id
    },
    owner () {
      return this.message.user
    },
    messageClass () {
      // let typeMess = this.user_id === message.
      return this.isOwner ? 'recive-msg' : 'sent-msg'
    },
    DLCardsBefore () {
      console.log(this.message)
      return this.message.DLCardsBefore
    },
    DLCardsAfter () {
      return this.message.DLCardsAfter
    },
  }
}
</script>

<style >
  .recive-msg p{
    color: white;
  }

  .msg {
    position: relative;
  }

  .recive-msg .message-hour{
    color: white;
  }

  .message-date-box{
    margin: 0px auto;
    margin-bottom: 15px;
    background: rgb(0,0,0,0.08);
    border-radius: 8px;
    padding: 1px 10px;
    display: inline-block;
    font-size: 12px;
    color: #080025;
  }

  .message-hour{
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    right: 10px;
  }
</style>
