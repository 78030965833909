<template>
    <div :class="DLClass">
        <div class="img" v-if="!isOwner && DLClass == 'received-card'" style="margin-bottom: 15px;">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idTenant + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        <div class="img" v-if="isOwner && DLClass == 'received-card'">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idOwner + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        <div class="text">
            <div :class="'alert-request ' + locationStatusMeta.class" style="margin-bottom: 15px;">
            <h3><i :class="'fas ' + locationStatusMeta.icon"></i>
            {{locationStatusMeta.msg}}
            </h3>
            <div class="doc-box">
                <div class="text-box">
                    <h3>{{locationTitle}}</h3>
                    <h4>
                    Du {{locationDateStart}} au {{locationDateEnd}}
                    </h4>
                    <p>Pour {{locationNumberDays}} jours</p>
                    <h5>{{locationPrice}} €</h5>
                    <h6><a :href="linkDL" class="btn btn-primary"> Voir la demande </a></h6>
                </div>
            </div>
        </div>
        <div class="img" v-if="!isOwner && DLClass == 'send-card'" style="margin-bottom: 15px;">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idTenant + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        <div class="img" v-if="isOwner && DLClass == 'send-card'">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idOwner + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LocationCard',
    props: {
        DLCard : {
            type: Object
        },
    },
    computed: {
        locationStatusMeta () {
            const statusMap = {
                created: {
                    class: 'alert-request1',
                    msg: 'Demande envoyée',
                    icon: 'fa-check'
                },
                accepted: {
                    class: 'alert-request5',
                    msg: 'Demande acceptée',
                    icon: 'fa-check'
                },
                paid: {
                    class: 'alert-request5',
                    msg: 'Demande payée',
                    icon: 'fa-check'
                },
                canceled: {
                    class: 'alert-request2',
                    msg: 'Demande annulée',
                    icon: 'fa-times'
                }
            }
            return statusMap[this.DLCard.state] || statusMap.created
        },
        locationTitle () {
            return this.DLCard.titreLocation
        },
        locationDateStart () {
            return this.$moment(this.DLCard.startAt).format('L')
        },
        locationDateEnd () {
            return this.$moment(this.DLCard.finishAt).format('L')
        },
        locationNumberDays () {
            return this.DLCard.panier.nbr_jour_utilisation
        },
        locationPrice () {
          if (this.isOwner) {
            return this.DLCard.ownerRevenuTTC
          } else {
            return this.DLCard.prix_total_ttc
          }
        },
        image () {
            return this.$parent.user && this.$parent.user.ImageProfil && this.$parent.user.ImageProfil.imageName
        },
        isOwner () {
            return this.auth === this.idOwner
        },
        auth() {
            return this.$parent.user_id
        },
        idOwner() {
            return this.DLCard.proprietaire.id
        },
        idTenant() {
            return this.DLCard.locataire.id
        },
        initials () {
            let initials
            if(this.DLCard.state == "accepted") {
                initials = this.DLCard.proprietaire.prenom.charAt(0) + "" + this.DLCard.proprietaire.nom.charAt(0)
            } else {
                initials = this.DLCard.locataire.prenom.charAt(0) + "" + this.DLCard.locataire.nom.charAt(0)
            }
            if(this.DLCard.state == "canceled") {
                initials = this.canceledByFirstName ? this.canceledByFirstName.charAt(0) + "" + this.canceledByLastName.charAt(0) : null
            }
            return initials
        },
        canceledBy () {
            return this.DLCard.locationProblem ? this.DLCard.locationProblem.by.id : null
        },
        canceledByFirstName () {
            return this.DLCard.locationProblem ? this.DLCard.locationProblem.by.prenom : null
        },
        canceledByLastName (){
            return this.DLCard.locationProblem ? this.DLCard.locationProblem.by.nom : null
        },
        DLClass () {
            if (this.DLCard.state === "canceled") {
                return this.auth === this.canceledBy ? 'send-card' : 'received-card'
            }

            const isMessageFromOwner = this.DLCard.state === "accepted"
            return (this.isOwner === isMessageFromOwner) ? 'send-card' : 'received-card'
        },
        linkDL () {
            return '/user/location/' + this.DLCard.id
        }
    }
}
</script>

<style scoped>
  .click-box {
    cursor: pointer;
  }
</style>