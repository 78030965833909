<template>
    <div class="modal-header d-flex flex-column">
        <h5 class="modal-title mb-2">
            {{$t('modals.cancel.title')}}
        </h5>
    </div>
    <div class="modal-body">
        <div v-if="stepThree">
            <div v-if="cancelPenalty">
                <p>
                    {{$t('modals.cancel.loc.typeCancel', {type: DL.typeAnnulation.type})}}
                    <a href="https://support.lightyshare.com/article/93-comment-annuler-une-reservation-validee" target="__blank">
                        {{$t('modals.cancel.loc.moreInfos')}}
                    </a>
                </p>
                <br><br>
                {{tNoRefund}}
                {{tRefund}}
                <div v-if="penaltyOne || penaltyTwo || penaltyThree">
                    {{$t('modals.cancel.loc.risk', {TTC: DL.prix_location_ttc, nbDays: cancelDifferenceDays})}}
                    <br><br>
                    <ul>
                        <li>
                            {{tPercentage}}
                        </li>
                        <li v-if="nbAnnulationsAnnee > 2">
                            {{$t('modals.cancel.loc.noRefund', {serviceCharge: DL.frais_service_ttc})}}
                        </li>
                        <li>
                            {{$t('modals.cancel.loc.badNotation')}}
                        </li>
                    </ul>
                </div>
                <br><br>
                <strong v-if="nbAnnulationsAnnee <= 2">{{$t('modals.cancel.loc.refund')}}</strong>
                <br><br>
            </div>
        </div>
        <strong v-if="limit3Hours">
            {{$t('modals.cancel.loc.limit', {dateLimit: this.datePaiement3})}}
            <br><br>
        </strong>
        <p>
            {{$t('modals.cancel.leaveMessage', {user: firstNameCapitalize})}}
        </p>
        <textarea name='message' rows="6" id="cancel-area" class="form-control" :placeholder="$t('modals.cancel.placeholder', {user: firstNameCapitalize})" v-on:keyup="enableButton()" v-model="message"></textarea>
    </div>
</template>

<script>
export default {
    name: 'LocationModalCancelLocataire',
    data() {
        return {
            message: ''
        }
    },
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        locationNotPaid() {
            return this.DL.PaidAt == null ? true : false
        },
        stepThree () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt !== null && this.termined == false && this.DL.CanceledAt === null ? true : false
        },
        cancelPenalty() {
            return this.moinsDe3Heures == 1 ? true : false
        },
        limit3Hours() {
            return this.DL.PaidAt !== null && this.moinsDe3Heures == 0 ? true : false
        },
        moinsDe3Heures () {
          if (this.$symfony.moinsDe3Heures !== null) {
            return this.$symfony.moinsDe3Heures
          } else if (this.DL && this.DL.paymentLessThen3Hours) {
            return this.DL.paymentLessThen3Hours
          } else {
            return 0
          }
        },
        datePaiement3 () {
          if (this.$symfony.datePaiement3) {
            return this.$moment(this.$symfony.datePaiement3).format('HH:mm')
          } else if (this.DL && this.DL.dateIntervalAfterPayment) {
            return this.$moment(this.DL.dateIntervalAfterPayment).format('HH:mm')
          } else {
            return ''
          }
        },
        firstNameCapitalize() {
            return this.capitalizeFirstLetter(this.proprietaire.prenom)
        },
        cancelDifferenceDays() {
          if (this.$symfony.cancelDifferenceDays) {
            return this.$symfony.cancelDifferenceDays
          } else {
            return this.DL.cancelDifferenceDays
          }
        },
        nbAnnulationsAnnee() {
          if (this.$symfony.nbAnnulationsAnnee !== null) {
            return this.$symfony.nbAnnulationsAnnee
          } else if (this.DL && this.DL.locataire && this.DL.locataire.countCancellation) {
            return this.DL.locataire.countCancellation
          } else {
            return 0
          }
        },
        penaltyOne() {
            return this.DL.typeAnnulation.id == 1 && this.cancelDifferenceDays <= 1 ? true : false
        },
        penaltyTwo() {
            return this.DL.typeAnnulation.id == 2 && this.cancelDifferenceDays <= 2 ? true : false
        },
        penaltyThree() {
            return this.DL.typeAnnulation.id == 3 && this.cancelDifferenceDays <= 9 ? true : false
        },
        noPenaltyOne() {
            return this.DL.typeAnnulation.id == 1 && this.cancelDifferenceDays > 1 ? true : false
        },
        noPenaltyTwo() {
            return this.DL.typeAnnulation.id == 2 && this.cancelDifferenceDays > 2 ? true : false
        },
        noPenaltyThree() {
            return this.DL.typeAnnulation.id == 3 && this.cancelDifferenceDays > 9 ? true : false
        },
        tNoRefund() {
            if (this.nbAnnulationsAnnee > 2) {
                if(this.noPenaltyOne) {
                    return this.$t('modals.cancel.loc.year.noRefund', {serviceCharge : this.DL.frais_service_ttc})
                }
                else if(this.noPenaltyTwo) {
                    return this.$t('modals.cancel.loc.year.noRefund', {serviceCharge : this.DL.frais_service_ttc})
                }
                else if(this.noPenaltyThree) {
                    return this.$t('modals.cancel.loc.year.noRefund', {serviceCharge : this.DL.frais_service_ttc})
                }
            }
        },
        tRefund() {
            if(this.nbAnnulationsAnnee < 2) {
                if(this.noPenaltyOne) {
                    return this.$t('modals.cancel.loc.year.refund')
                }
                if(this.noPenaltyTwo) {
                    return this.$t('modals.cancel.loc.year.refund')
                }
                if(this.noPenaltyThree) {
                    return this.$t('modals.cancel.loc.year.refund')
                }
            }
        },
        tPercentage() {
            if(this.DL.typeAnnulation.id == 1) {  
                if(this.cancelDifferenceDays === 1) {
                    return this.$t('modals.cancel.loc.percentage.25', {TTC : 0.25 * (this.DL.frais_service_ttc)})
                } else if(this.cancelDifferenceDays === 0) {
                    return this.$t('modals.cancel.loc.percentage.50', {TTC : 0.50 * (this.DL.frais_service_ttc)})
                }
            }
            if(this.DL.typeAnnulation.id == 2) {       
                if(this.cancelDifferenceDays < 3 && this.cancelDifferenceDays > 1){
                    return this.$t('modals.cancel.loc.percentage.25', {TTC : 0.25 * (this.DL.frais_service_ttc)})
                } else if(this.cancelDifferenceDays === 1) {
                    return this.$t('modals.cancel.loc.percentage.50', {TTC : 0.50 * (this.DL.frais_service_ttc)})
                } else if(this.cancelDifferenceDays === 0) {
                    return this.$t('modals.cancel.loc.percentage.100', {TTC : this.DL.frais_service_ttc})
                }
            }
            if(this.DL.typeAnnulation.id == 3) {
                if(this.cancelDifferenceDays < 10 && this.cancelDifferenceDays >= 5) {
                    return this.$t('modals.cancel.loc.percentage.25', {TTC : 0.25 * (this.DL.frais_service_ttc)})
                }
                if(this.cancelDifferenceDays < 5) {
                    return this.$t('modals.cancel.loc.percentage.100', {TTC : this.DL.frais_service_ttc})
                }
            }      
        }
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        enableButton() {
            const btn = document.getElementById('btn-cancel')
            if(this.message.length > 0) {
                btn.classList.remove('disabled')
                this.$emit('messageChanged', this.message)
            } else {
                btn.classList.add('disabled')
                this.$emit('messageChanged', '')
            }
        }
    }
}
</script>