<template>
  <el-menu
      :default-openeds="menuActive"
      :default-active="itemOpenIndex"
      class="el-menu-vertical-demo"
      :unique-opened="true"
  >
    <menu-item v-for="item in routesItems" :item="item" :link-class="'espace-loueur'">
    </menu-item>
  </el-menu>
  <div class="bottom-menu">
    <el-menu
        :unique-opened="true"
    >
      <menu-item v-for="item in userRoutesItems" :item="item" >
      </menu-item>
    </el-menu>
  </div>
</template>
<script>
// import 'element-plus/theme-chalk/display.css'
import MenuItem from "./MenuItem.vue"
import Auth from "../modules/auth/services/Auth";
import { asksStatusSlug, reverseMap } from "~/modules/ask/maps/asks-maps.js";

Array.prototype.insert = function ( index, ...items ) {
  this.splice( index, 0, ...items );
};

export default {
  name: "TenantLeftBar",
  emits: ['item-click'],
  props: {
    aside: {
      type: Boolean,
      default: true
    },
    header: {
      type: Boolean,
      default: true
    },
    menuClass: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    }
  },
  components: {
    MenuItem
  },
  data () {
    return {
      bottomMenu: [
        {
          icon: '&#xe989',
          label: 'Support',
          to: { href: 'https://support.lightyshare.com', target: '_blank' }
        },
        {
          icon: '&#xe991',
          label: 'Se déconnecter',
          class: 'grey',
          onClick: 'handleLogout'
        }
      ]
    }
  },
  methods: {
    generateAsksIsActiveItem (status) {
      return function (route) {
        if (route.name === status) {
          return true
        } else if (route.params && route.params.status) {
          const reversedSlug = reverseMap(asksStatusSlug)
          return reversedSlug[route.params.status] === status
        }
      }
    },
    handleLogout() {
      window.location.href = '/logout';
    },
    openBeacon () {
      Beacon('navigate', '/ask/')
      Beacon('open')
    },
    formatMenuItem (item, key) {
      key = key + 1
      let route = false
      let submenu = []
      let classes = ''
      if (item.class) {
        classes = item.class
      }
      let tagElement = 'el-menu-item'
      if (typeof item.to !== 'undefined') {
        route = this.$router.resolve(item.to)
      }
      if (Array.isArray(item.submenu)) {
        tagElement = 'el-sub-menu'
        let submenuKey = key + '-'
        submenu = item.submenu.map((item, subKey) => {
          const itemMenu = this.formatMenuItem(item, subKey)
          itemMenu['key'] = submenuKey+itemMenu['key']
          return {
            ...itemMenu
          }
        })
      }
      return {
        ...item,
        key: '' + key,
        route,
        submenu,
        class: classes
      }
    },
    findRoute (acc, item) {
      if (typeof item.isActive === 'function') {
        return item.isActive(this.$route) ? item.key : acc
      } else if (item.route && item.route.name && item.route.name === this.$route.name) {
        return item.key
      }
      if (!acc && Array.isArray(item.submenu)) {
        return item.submenu.reduce(this.findRoute, false)
      }
      return acc
    }
  },
  computed: {
    defaultOpenIndex () {
      // return this.itemOpenIndex.split('-')[0]
    },
    menuActive () {
      let item = []
      if (this.itemOpenIndex && this.itemOpenIndex.split('-').length) {
        item.push(this.itemOpenIndex.split('-')[0])
      }
      return item
    },
    submenuActive () {
      return [this.itemOpenIndex.split('-')[1]]
    },
    itemOpenIndex () {
      const routesIndex = this.routesItems.reduce(this.findRoute, false)
      console.log('itemOpenIndex')
      console.log(routesIndex)
      return routesIndex ? routesIndex : ''
    },
    auth () {
      const authData = Auth.query().first();
      console.log('All Auth properties:', Object.keys(authData));
      console.log('Full Auth object:', JSON.stringify(authData, null, 2));
      return authData;
    },
    itemsMenu() {
      console.log('Computing itemsMenu');
      console.log('Auth in itemsMenu:', this.auth);
      console.log('BoutiqueActive in itemsMenu:', this.auth?.boutiqueActive);
      return [
        {
          icon: '&#xe97d;',
          label: 'Favoris',
          to: { href: '/user/mes-favoris'}
        },
        {
          icon: '&#xe9d5',
          label: 'Panier',
          to: {name: "my-carts"}
        },
        {
          icon: '&#xea16',
          label: 'Locations',
          to: { name: 'tenant-asks'}
        },
        {
          icon: '&#xe999;',
          label: 'Messagerie',
          to: { href: '/user/messagerie-new'}
        },
        {
          icon: '&#xe928',
          label: 'Explorer',
          to: { href: '/catalogue-search'}
        },
        ...(this.$symfony && this.$symfony.auth && this.$symfony.auth.boutiqueActive ? [{
          icon: '&#xe980',
          label: 'Espace loueur',
          to: { name: 'dashboard'},
        }] : []),
        {
          icon: '&#xe9d5',
          label: 'Paramètres',
          submenu: [
            {
              label: 'Profil'
            },
            {
              label: 'Compte'
            },
            {
              label: 'Réseau sociaux'
            },
            {
              label: 'Certification'
            },
            {
              label: 'Sécurité'
            },
            {
              label: 'Paiement'
            }
          ]
        }
      ]
    },
    userRoutesItems () {
      const items = [
        ...this.bottomMenu
      ]
      return items.map(this.formatMenuItem)
    },
    routesItems () {
      const items = [
        ...this.itemsMenu
      ]
      if (this.auth && Array.isArray(this.auth.rolesList) && this.auth.rolesList.includes('ROLE_ADMIN')) {
        items.push({
          icon: '&#xe950;',
          label: 'Admin',
          to: { name: 'admin-statistics' }
        })
      }
      return items.map(this.formatMenuItem)
    }
  }
}
</script>
<style lang="scss">
body.no-margin {
  margin-top: 0px;
}
.bottom-menu {
  border-top: solid 1px #EAECF0;
  padding: 24px 20px;
}
.grey {
  color: #CCC !important;
  & .router-link-active {
    color: #CCC !important;
  }
  & .router-link-exact-active {
    color: #CCC !important;
    &:hover {
      color: var(--el-color-primary) !important;
    }
  }
  &:hover {
    color: var(--el-color-primary) !important;
  }
}
.inner_left_drawer {
  /*top: 0px;*/
  /*position: absolute;*/
  /*background: red;*/
  /*width: 216px;*/
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 18px -3px rgba(8, 0, 37, 0.10);
  display: grid;
  font-family: "Inter", sans-serif;
  grid-template-rows: auto 1fr auto;
  width: 250px;
  height: 100vh; /* Ceci assure que la grille remplit la hauteur de la fenêtre de visualisation. Vous pouvez ajuster cette valeur selon vos besoins. */
  gap: 10px;
}
.el-menu {
  border-right: 0px;
  border-right: none;
}
.icon-kit, [class^="icon-"] {
  font-weight: 200 !important; /* Rend les icônes plus fines */
  -webkit-font-smoothing: antialiased;
}
.top-logo {
  height: 31px;
  margin: 24px 32px 24px 24px;
  margin-bottom: 5px;
  width: auto;
}
</style>
