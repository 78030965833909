<template>
    <div class="modal fade show" :style="styleModal">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 800px;">
            <div class="modal-content">
                <form method='POST' :action="path(cancelBy,{'id': DL.id})">
                    <input type="hidden" name="message" :value="message">
                    <LocationModalCancelProprietaire v-if="isOwner" :auth="auth" :DL="DL" :termined="termined"/>
                    <LocationModalCancelLocataire v-if="isTenant" :auth="auth" :DL="DL" :termined="termined" @messageChanged="onMessageChanged"/>
                    <div class="modal-footer">
                        <div>
                            <button type="button" class="btn btn-short btn-link" data-dismiss="modal" @click="closeModalCancel()">
                                {{$t('modals.cancel.close')}}
                            </button>
                            <input v-if="enableBtnCancel" type="submit" :value="$t('modals.cancel.btnCancel')" class="btn btn-short btn-primary" id="btn-cancel">
                            <input v-if="!enableBtnCancel" type="submit" :value="$t('modals.cancel.btnCancel')" :class="isDisabled" id="btn-cancel"> 
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>

import LocationModalCancelLocataire from "./LocationModalCancelLocataire.vue";
import LocationModalCancelProprietaire from "./LocationModalCancelProprietaire.vue";

export default {
    name: 'LocationModalCancel',
    emits: ["closeModalCancel"],
    components: {
        LocationModalCancelLocataire,
        LocationModalCancelProprietaire
    },
    data() {
        return {
            disable: "btn btn-short btn-primary disabled",
            styleModal: "padding-right: 19px; display: block; background-color: rgba(0,0,0,0.3)",
            message: ''
        }
    },
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        cancelBy() {
            if(this.isOwner) {
                return 'cancel_demandeLocation_proprietaire'
            } else if (this.isTenant) {
                return 'cancel_demandeLocation_locataire'
            }
        },
        paniersAnnonce () {
            return this.DL.panier.paniersAnnonce
        },
        enableBtnCancel() {
            return this.DL.AcceptedAt === null && this.isOwner ? true : false
        },
        isDisabled: {
            get() {
                return this.disable
            },
            set(value) {
                this.disable = value
            }
        }
    },
    methods: {
        closeModalCancel() {
            this.$emit('closeModalCancel', false);
        },
        onMessageChanged(message) {
            this.message = message;
        }
    }
}
</script>