<script>
import UserTicketCard from "~/modules/user/components/UserTicketCard.vue";

export default {
  name: "AskResume",
  components: {
    UserTicketCard,
  },
  props: {
    userType: {
      type: String,
      validator: function (value) {
        return ['owner', 'tenant'].includes(value)
      },
    },
    ask: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    user () {
      if (!this.ask) {
        return null
      }
      if (this.userType === 'owner') {
        return this.ask.locataire
      } else {
        return this.ask.proprietaire
      }
    },
    cart ()
    {
      return this.ask ? this.ask.panier : null
    },
    pricesInfos () {
      let lines = []
      if (this.ask && this.ask.remboursement && this.ask.remboursement.montantRemboursementProprietaire) {
        let tooltip = null
        if (this.ask.remboursement.penaltyPercent) {
          tooltip = this.ask.remboursement.penaltyPercent + '% de pénalitée a été appliqué '
        }
        lines.push({
          label: this.userType === 'tenant' ? "Pénalités d'annulation" : "Frais d'annulation",
          price: this.$currencyFormat.format(this.montantHT),
          tooltip,
        })
        
        if (this.userType === 'tenant' && this.ask.frais_service_ht) {
          lines.push({
            label: 'Frais de service',
            price: this.$currencyFormat.format(this.ask.frais_service_ht)
          })
        }
      } else if (this.cart) {
        lines.push({
          label: this.$currencyFormat.format(this.cart.prixNormal / this.cart.nbr_jour_utilisation) + ' x ' + this.cart.nbr_jour_utilisation + ' jour(s)',
          price: this.$currencyFormat.format(this.cart.prixNormal)
        })
        if ((parseFloat(this.cart.prixNormal) - parseFloat(this.cart.prixLocationHt)) > 0) {
          lines.push({
            label: 'Dégressivité',
            labelClasses: 'success-color',
            price: '-' + this.$currencyFormat.format(parseFloat(this.cart.prixNormal) - parseFloat(this.cart.prixLocationHt)),
            priceClasses: 'success-color',
          })
        }
        if (this.ask.price_before_promo) {
          lines.push({
            label: 'Code promo',
            labelClasses: 'success-color',
            price: '-' + this.$currencyFormat.format(parseFloat(this.ask.price_before_promo) - parseFloat(this.ask.prix_location_ht)),
            priceClasses: 'success-color',
          })
        }
        if (parseFloat(this.cart.serviceTotalHt)) {
          lines.push({
            label: 'Service',
            price: this.$currencyFormat.format(this.cart.serviceTotalHt)
          })
        }
        if (this.userType === 'tenant') {
          if (parseFloat(this.cart.assuranceHt)) {
            lines.push({
              label: 'Assurance',
              price: this.$currencyFormat.format(this.cart.assuranceHt)
            })
          }
          lines.push({
            label: 'Frais de service',
            price: this.$currencyFormat.format(this.ask.frais_service_ht)
          })
        }
      }

      if (this.ask && this.ask.remboursement && this.ask.remboursement.choixProprietaire === 'NO_PENALTY') {
        lines = lines.map((line) => {
          if (!line.priceClasses) {
            line.priceClasses = ''
          }
          line.priceClasses += ' canceled-value'
          return line
        })
      }

      return lines.map((line) => {
        return {
          labelClasses: '',
          priceClasses: '',
          ...line,
        }
      })
    },
    total ()
    {
      let lines = []
      if (!this.ask) {
        return lines
      }
      let totalHTPrice = this.ask.prix_total_ht
      let TVAPrice = this.ask.prix_total_ttc - this.ask.prix_total_ht
      let totalTTCPrice = this.ask.prix_total_ttc
      
      if (this.ask.remboursement && this.ask.remboursement.montantRemboursementProprietaire) {
        if (this.userType === 'tenant') {
          const penalitesHT = Number(this.montantHT) || 0
          const fraisServiceHT = Number(this.ask.frais_service_ht) || 0
          
          totalHTPrice = penalitesHT + fraisServiceHT
          
          TVAPrice = totalHTPrice * 0.2
          
          totalTTCPrice = totalHTPrice + TVAPrice
        } else {
          totalHTPrice = Number(this.montantHT)
          TVAPrice = Number(this.montantTVA)
          totalTTCPrice = Number(this.montantTTC)
        }
      } else if (this.userType === 'owner') {
        totalHTPrice = this.ask.ownerRevenuHT
        TVAPrice = this.ask.ownerRevenuTTC - this.ask.ownerRevenuHT
        totalTTCPrice = this.ask.ownerRevenuTTC
      }

      if (this.userType === 'tenant') {
        lines.push({
          label: 'Total HT',
          price: this.$currencyFormat.format(totalHTPrice)
        }, {
          label: 'TVA',
          price: this.$currencyFormat.format(TVAPrice)
        }, {
          label: 'Total TTC',
          price: this.$currencyFormat.format(totalTTCPrice),
          priceClasses: 'total-bold',
          labelClasses: 'price-total-bold'
        })
        if (this.ask && this.ask.remboursement && this.ask.paidAtUnix && this.ask.prix_total_ttc - this.ask.remboursement.montantRemboursementLocataire) {
          lines.push({
            label: 'Remboursement',
            price: this.$currencyFormat.format(this.ask.remboursement.montantRemboursementLocataire),
            priceClasses: 'total-bold',
            labelClasses: 'price-total-bold'
          })
        }
      } else {
        lines.push({
          label: 'Revenu HT',
          price: this.$currencyFormat.format(totalHTPrice)
        }, {
          label: 'TVA',
          price: this.$currencyFormat.format(TVAPrice)
        }, {
          label: 'Revenu TTC',
          price: this.$currencyFormat.format(totalTTCPrice),
          priceClasses: 'price-total-bold',
          labelClasses: 'total-bold'
        })
      }
      if (this.ask && this.ask.remboursement && this.ask.remboursement.choixProprietaire === 'NO_PENALTY') {
        lines = lines.map((line) => {
          if (!line.priceClasses) {
            line.priceClasses = ''
          }
          line.priceClasses += ' canceled-value'
          return line
        })
      }
      return lines.map((line) => {
        return {
          labelClasses: '',
          priceClasses: '',
          ...line,
        }
      })
    },
    montantHT() {
      if (!this.ask?.remboursement?.montantRemboursementProprietaire) return 0
      const tva = this.ask?.taxComputation?.tax || 0
      return (this.ask.remboursement.montantRemboursementProprietaire / (1 + (tva/100))).toFixed(2)
    },
    montantTVA() {
      if (!this.ask?.remboursement?.montantRemboursementProprietaire) return 0
      const tva = this.ask?.taxComputation?.tax || 0
      const ht = this.ask.remboursement.montantRemboursementProprietaire / (1 + (tva/100))
      return (this.ask.remboursement.montantRemboursementProprietaire - ht).toFixed(2)
    },
    montantTTC() {
      return this.ask?.remboursement?.montantRemboursementProprietaire || 0
    }
  },
  watch: {
    ask: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) return
      }
    }
  },
}
</script>

<template>
  <div class="ask-resume-card">
    <UserTicketCard :user="user"></UserTicketCard>
    <hr>
    <slot name="between">
    </slot>
    <div class="price-resume-panel">
      <div v-if="pricesInfos.length">
        <div v-for="info in pricesInfos" class="panel">
          <span :class="info.labelClasses">{{ info.label }}  <el-tooltip v-if="info.tooltip" placement="top" popper-class="small-popper" style="margin-left: 5px;">
            <template #content> {{ info.tooltip }}</template>
            <span class="icomoon">&#xe983</span>
          </el-tooltip></span>
          <span :class="info.priceClasses">{{ info.price }}</span>
        </div>
        <hr>
      </div>
      <div v-for="t in total" class="panel">
        <span :class="t.labelClasses">{{ t.label }}</span>
        <span :class="t.priceClasses">{{ t.price }}</span>
      </div>
    </div>
    <slot name="after">
    </slot>
  </div>
</template>

<style scoped>

</style>